export const GET_USER_LOGGEDIN="GET_USER_LOGGEDIN"
export const GET_NAVIGATION="GET_NAVIGATION"
export const GET_ROLES="GET_ROLES"
export const GET_ROL="GET_ROL"
export const GET_ACCESS="GET_ACCESS"
export const POST_ACCESS="POST_ACCESS"
export const DELETE_ACCESS="DELETE_ACCESS"
export const PUT_ROLE="PUT_ROLE"
export const POST_ROLE="POST_ROLE"
export const DELETE_ROLE="DELETE_ROLE"
export const POST_USER="POST_USER"
export const GET_USERS="GET_USERS"
export const DELETE_USER="DELETE_USER"
export const PUT_USER="PUT_USER"
export const GET_USER="GET_USER"
export const GET_PROJECTS="GET_PROJECTS"
export const GET_PROJECT="GET_PROJECT"
export const POST_PROJECT="POST_PROJECT"
export const PUT_PROJECT="PUT_PROJECT"
export const DELETE_PROJECT="DELETE_PROJECT"
export const GET_MEMBERS_PROJECT="GET_MEMBERS_PROJECT"
export const POST_MEMBER="POST_MEMBER"
export const DELETE_MEMBER="DELETE_MEMBER"
export const GET_SURVEYS="GET_SURVEYS"
export const GET_SURVEYS_RESPONSES="GET_SURVEYS_RESPONSES"
export const GET_SURVEYS_GRAPHS="GET_SURVEYS_GRAPHS"
export const GET_SURVEY="GET_SURVEY"
export const POST_SURVEY="POST_SURVEY"
export const PUT_SURVEY="PUT_SURVEY"
export const DELETE_SURVEY="DELETE_SURVEY"
export const DUPLICATE_SURVEY="DUPLICATE_SURVEY"
export const POST_SECTION="POST_SECTION"
export const PUT_SECTION="PUT_SECTION"
export const DELETE_SECTION="DELETE_SECTION"
export const POST_QUESTION="POST_QUESTION"
export const PUT_QUESTION="PUT_QUESTION"
export const DELETE_QUESTION="DELETE_QUESTION"
export const POST_OPTION="POST_OPTION"
export const PUT_OPTION="PUT_OPTION"
export const DELETE_OPTION="DELETE_OPTION"
export const GET_PROVINCES="GET_PROVINCES"
export const GET_DPA="GET_DPA"
